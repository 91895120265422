import React from "react";
import { FaStar } from "react-icons/fa";
import useFetch from "../ApiClient/GetApi";

export default function Rating() {
    const { apiData } = useFetch("getRating");

    const renderStars = (rating) => (
        <div>
            {[1, 2, 3, 4, 5].map((star) => (
                <FaStar
                    key={star}
                    size={24}
                    color={star <= rating ? "#ffc107" : "#e4e5e9"}
                />
            ))}
        </div>
    );

    return (
        <div className="page-wrapper" style={{ padding: "100px 0px", }}>
            <div className="container">
                {/* <div className="page-header"> */}
                    <div className="section_heading margin-bottom">

                        <h2>Rating</h2>
                    </div>
                {/* </div> */}

                {apiData?.data ? (
                    <div className="row align-items-center">
                        {/* Controller Rating */}
                        <div className="col-md-6 text-center">
                            <h5>Controller Rating</h5>
                            {renderStars(apiData.data.controller)}
                            <p>Rating: {apiData.data.controller}</p>
                        </div>

                        {/* Agent Rating */}
                        <div className="col-md-6 text-center">
                            <h5>Agent Rating</h5>
                            {renderStars(apiData.data.agent)}
                            <p>Rating: {apiData.data.agent}</p>
                        </div>
                    </div>
                ) : (
                    <p>No ratings available.</p>
                )}
            </div>
        </div>
    );
}
