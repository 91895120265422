import React from "react";
import Slider from "react-slick";
import useFetch from "../ApiClient/GetApi";
import { IMAGE_URL } from "../Utils/urls";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function AddSection() {
  const { apiData, loading, error } = useFetch("getAdds");

  if (loading) {
    return <div>Loading advertisements...</div>;
  }

  if (error) {
    return <div>Error loading advertisements. Please try again later.</div>;
  }

  if (!apiData?.data?.length) {
    return <div>No advertisements available at the moment.</div>;
  }

  // Carousel settings
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024, // Adjust for tablets
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768, // Adjust for mobile
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="add-section">
      <div className="container">
        <div className="section_heading margin-bottom">
          <h2>Ads</h2>
        </div>

        <Slider {...settings}>
          {apiData.data.map((ad, index) => (
            <div key={index} className="ad-card" style={{ textAlign: "center", padding: "0px 15px",margin:"10px !important" }}>
              <a href={`https://${ad.url}`} target="_blank" rel="noopener noreferrer">
                <img
                  src={`${IMAGE_URL}${ad.image}`}
                  alt="Advertisement"
                  style={{
                    width: "100%",
                    height: "200px",
                    objectFit: "cover",
                    border: "1px solid lightgray",
                    borderRadius: "5px",
                  }}
                />
              </a>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}
